import PermissionGate from '@/components/basic/permission-gate';
import { ZeroChannelsCustomers } from '@/components/metrics/channels/ZeroChannelsCustomers';
import { LatestAccessTimeAgo } from '@/components/metrics/users/LatestAccessTimeAgo';

import { Permission } from '../../../../../common/permissions';

const RequiresAttentionWidget: React.FC = () => {
    return (
        <div className="requires-attention">
            <PermissionGate permissions={[Permission.customersChannelsList, Permission.customersMetricsView]}>
                <ZeroChannelsCustomers />
            </PermissionGate>
            <PermissionGate permissions={[Permission.customersMetricsView]}>
                <LatestAccessTimeAgo moreThan={ 1000*60*60*24*30 } />
            </PermissionGate>
        </div>
    );
};

export default RequiresAttentionWidget;
