import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { pick } from 'lodash';

import getThemeColors from '@/utils/getThemeColors';

import { Onboarding } from '../../../../../../common/analytics';

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

export const formatChartDate = (
  value: number,
  groupBy: Onboarding.DateGroup
) => {
  const date = dayjs(value);

  switch (groupBy) {
    case Onboarding.DateGroup.day:
      return date.format('MMM DD');
    case Onboarding.DateGroup.week:
      return date.format('W');
    case Onboarding.DateGroup.month:
      return date.format('MMM YYYY');
    default:
      return date.format('YYYY');
  }
};

export const getRandomColor = () => {
  const themeColors = getThemeColors();
  const colors = Object.values(
      pick(themeColors, [
          'primaryBlue',
          'primaryGreen',
          'primaryRed',
          'primaryOrange',
          'secondaryRed',
          'secondaryGreen',
          'primaryYellow',
          'secondaryYellow',
          'secondaryBlue',
      ]),
  );

  return colors[Math.floor(Math.random() * colors.length)];
};

export const transformByPartnerData = (data: Onboarding.DateByPartnerCount[]) => {
  const partnerIds = [...new Set(data.flatMap((item) => item.partners.map((partner) => partner.partnerId)))];

  return data.map((item) => {
      const transformedItem: { [key: string]: string | number } = { date: item.date };

      partnerIds.forEach((partnerId) => {
          const partner = item.partners.find((p) => p.partnerId === partnerId);

          transformedItem[partnerId || 'none'] = partner ? partner.count : 0;
      });

      return transformedItem;
  });
};
