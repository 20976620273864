import type { Customer } from "../../../../../../common";

import "../../index.less";

import dayjs from "dayjs";
import { type FC, useState } from "react";
import { useSelector } from "react-redux";

import CustomerAPI from "@/api/services/customerApi.abstract";
import WithCustomerSelect from "@/components/filters/WithCustomerSelect";
import { useLocale } from "@/locales";
import { getCustomerBaseUrl } from "@/utils/getCustomerBaseUrl";

import WithDateRangePicker from "../../../../components/filters/WithDateRangePicker";
import AgentMessagesCount from "./components/AgentMessagesCount";

const AgentMessagesCountPage: FC = () => {
  const { user } = useSelector((state) => state.user);
  const { dateRange } = useSelector((state) => state.filters);
  const { instances } = useSelector((state) => state.instances);
  const { formatMessage } = useLocale();

  const [customer, setCustomer] = useState<Customer.Info | null>(null);

  const isApiSupportedForCustomer = (customer: Customer.Info) => {
    const instance = instances.find((i) => i._id === customer.instance);

    return !!instance && CustomerAPI.isVersionSupportedByInstance(2, instance);
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    return (
      current > dayjs().endOf("day") ||
      current < dayjs().add(-90, "d").startOf("day")
    );
  }

  return (
    <WithCustomerSelect
      position="left"
      onCustomerChange={setCustomer}
      postFilter={isApiSupportedForCustomer}
    >
      <WithDateRangePicker
        position="right"
        disabledDate={disabledDate}
        presets={["last90Days", "last30Days", "last7Days",]}
      >
        <div className="metrics-container">
          {customer ? (
            <AgentMessagesCount
              userPermissions={user!.permissions}
              customerId={customer._id}
              customerBaseUrl={getCustomerBaseUrl(customer, instances)}
              dateRange={dateRange}
            />
          ) : (
            <div className="no-customer-description">
              {formatMessage({ id: "customers.select.noCustomerSelected" })}
            </div>
          )}
        </div>
      </WithDateRangePicker>
    </WithCustomerSelect>
  );
};

export default AgentMessagesCountPage;
