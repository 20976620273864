import './index.less';

import { type FC } from 'react';
import { useSelector } from 'react-redux';

import DashboardCard from '@/components/dashboard/DashboardCard/DashboardCard';
import ChannelsWidget from '@/components/dashboard/widgets/ChannelsWidget';
import NewCustomersByYearWidget from '@/components/dashboard/widgets/NewCustomersByYearWidget';
import RequiresAttentionWidget from '@/components/dashboard/widgets/RequiresAttentionWidget';
import { useLocale } from '@/locales';

const DashBoardPage: FC = () => {
    const { formatMessage } = useLocale();
    const { user } = useSelector((state) => state.user);

    const cards = [
        {
            id: 'newCustomers',
            title: formatMessage({ id: 'dashboard.card.newCustomers' }),
            linkTo: '/analytics/new-customers',
            classNames: 'allow-overflow',
            component: <NewCustomersByYearWidget groupByPartner={!user?.partnerId} />,
        },
        {
            id: 'requiresAttention',
            title: formatMessage({ id: 'dashboard.requiresAttention.alert' }),
            component: <RequiresAttentionWidget />,
            important: true,
        },
        {
            id: 'channels',
            title: formatMessage({ id: 'metrics.channels.title' }),
            component: <ChannelsWidget />,
        },
    ];

    return (
        <div className="container">
            <div className="dashboard-content">
                {cards.map(({ id, title, linkTo, important, component, classNames }) => (
                    <DashboardCard key={id} title={title} linkTo={linkTo} important={important} classNames={classNames}>
                        {component}
                    </DashboardCard>
                ))}
            </div>
        </div>
    );
};

export default DashBoardPage;
