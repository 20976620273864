import type { Metrics } from '../../../../common/routes';

import { request } from '../request';

export const apiGetUsersLatestAccess = (query: Metrics.Users.GetLatestAccessQuery) => {
    return request<Metrics.Users.GetLatestAccessResponse>(
        'get', 
        '/metrics/users/latest-access', 
        query
    );
};
