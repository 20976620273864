import type { Partners } from '../../../../../../../common';
import type { DateRange } from '@/interface/dateRange';

import { Divider, Segmented } from 'antd';

import MyCheckBox from '@/components/basic/checkbox';
import MySelect from '@/components/basic/select';
import FilterWrapper from '@/components/filters/components/Wrapper';
import { LocaleFormatter } from '@/locales';

import { Onboarding } from '../../../../../../../common/analytics';

interface Props {
    groupBy: Onboarding.DateGroup;
    setGroupBy: (groupBy: Onboarding.DateGroup) => void;
    partnerId: string | undefined;
    setPartnerId: (partnerId: string | undefined) => void;
    partners: Partners.Partner[];
    onPartnerSearch: (query: string) => void;
    hasPartner: boolean;
    groupByPartner: boolean;
    setGroupByPartner: (groupByPartner: boolean) => void;
    compareToPast: boolean;
    setCompareToPast: (compareToPast: boolean) => void;
    dateRange: DateRange;
}

const Filters: React.FC<Props> = ({
    groupBy,
    setGroupBy,
    partnerId,
    setPartnerId,
    partners,
    onPartnerSearch,
    hasPartner,
    groupByPartner,
    setGroupByPartner,
    compareToPast,
    setCompareToPast,
    dateRange,
}) => {
    return (
        <div>
            {!hasPartner && (
                <>
                    <FilterWrapper position="left" order={2}>
                        <div className="filters-box">
                            <div className="content">
                                <label className="text-2">
                                    <LocaleFormatter id="analytics.newCustomers.filters.partner" />
                                </label>
                                <MySelect
                                    value={partnerId}
                                    onChange={setPartnerId}
                                    onSearch={onPartnerSearch}
                                    size="large"
                                    options={partners.map(({ name, _id }) => ({
                                        label: name,
                                        value: _id,
                                    }))}
                                    filterOption={false}
                                    placeholder="Partner Name"
                                    className="search-partner-select"
                                    allowClear
                                    showSearch
                                >
                                    {partners?.map((partner) => (
                                        <MySelect.Option key={partner._id} value={partner._id}>
                                            {partner.name}
                                        </MySelect.Option>
                                    ))}
                                </MySelect>
                            </div>
                            <Divider type="vertical" />
                        </div>
                    </FilterWrapper>
                    <FilterWrapper position="left" order={3}>
                        <div className="filters-box">
                            <MyCheckBox checked={groupByPartner} onChange={(e) => setGroupByPartner(e.target.checked)}>
                                <LocaleFormatter id="analytics.newCustomers.filters.groupByPartner" />
                            </MyCheckBox>
                            <Divider type="vertical" />
                        </div>
                    </FilterWrapper>
                </>
            )}
            <FilterWrapper position="left" order={1}>
                <div className="filters-box">
                    <MyCheckBox
                        checked={compareToPast}
                        onChange={(e) => setCompareToPast(e.target.checked)}
                        disabled={groupByPartner || dateRange.from === 0}
                    >
                        <LocaleFormatter id="analytics.newCustomers.filters.compareToPast" />
                    </MyCheckBox>
                    <Divider type="vertical" />
                </div>
            </FilterWrapper>
            <FilterWrapper position="left" order={0}>
                <div className="filters-box">
                    <div className="content">
                        <label className="text-2">
                            <LocaleFormatter id="analytics.newCustomers.filters.groupBy" />
                        </label>
                        <Segmented
                            value={groupBy}
                            onChange={(groupBy) => setGroupBy(groupBy as Onboarding.DateGroup)}
                            options={Object.values(Onboarding.DateGroup).map((value) => ({
                                value,
                                label: value,
                            }))}
                            className="group-by-segment-selector"
                            size="large"
                        />
                    </div>
                    <Divider type="vertical" />
                </div>
            </FilterWrapper>
        </div>
    );
};

export default Filters;
