

import type { Onboarding } from "../../../../../../common/analytics";

import { pick } from "lodash";
import { Area, Legend, XAxis, YAxis } from "recharts";

import AreaChart from "@/components/basic/shared/charts/AreaChart";
import { formatChartDate } from "@/pages/analytics/pages/newCustomers/helpers";
import getThemeColors from "@/utils/getThemeColors";

const generateLinearGradients = (data: Data<true>) => {
  const uniqueKeys = new Set<string>();
  const colors = [
    ...Object.values(
      pick(getThemeColors(), [
        "primaryBlue",
        "primaryGreen",
        "primaryRed",
        "primaryOrange",
        "secondaryRed",
        "secondaryGreen",
      ])
    ),
  ];

  data.forEach((entry) =>
    Object.keys(entry).forEach((key) => {
      if (key !== "date") uniqueKeys.add(key);
    })
  );

  return Array.from(uniqueKeys).map((key, i) => {
    const color = colors[i];

    return (
      <linearGradient id={`color${key}`} x1="0" y1="0" x2="0" y2="1" key={key}>
        <stop offset="5%" stopColor={color} stopOpacity={0.9} />
        <stop offset="95%" stopColor={color} stopOpacity={0.4} />
      </linearGradient>
    );
  });
};

type Data<GroupByPartner extends boolean> = GroupByPartner extends true
  ? {
      [key: string]: number | string;
    }[]
  : Onboarding.DateCount[];

interface Props<GroupByPartner extends boolean = false> {
  data: Data<GroupByPartner>;
  groupBy: Onboarding.DateGroup;
  groupByPartner: GroupByPartner;
}

const NewCustomersChart = <GroupByParner extends boolean = false>({
  data,
  groupByPartner,
  groupBy,
}: Props<GroupByParner>) => {
  const gradients = generateLinearGradients(data);
  const partners = Object.keys(data[0] ?? {}).filter((key) => key !== 'date');

  return (
    <AreaChart
      data={data}
      dataKey="count"
      tooltipProps={{
        labelFormatter: (value) => formatChartDate(value, groupBy),
      }}
    >
      <defs>{gradients}</defs>
      <XAxis
        dataKey="date"
        tickFormatter={(value) => formatChartDate(value, groupBy)}
        minTickGap={20}
      />
      <YAxis allowDecimals={false} />
      {groupByPartner && <Legend verticalAlign="top" height={partners.length > 6 ? 52 : 36} />}
      {partners.map((key) => (
          <Area
            key={key}
            type="linear"
            dataKey={key}
            animationDuration={500}
            stackId="1"
            stroke={`url(#color${key})`}
            fill={`url(#color${key})`}
            fillOpacity={1}
          />
        ))}
    </AreaChart>
  );
};

export default NewCustomersChart;
