import type { Analytics } from "../../../common/routes";

import { request } from "./request";

export const apiGetNewByDate = (
  query?: Analytics.Onboarding.GetNewByDateQuery
) => {
  return request<Analytics.Onboarding.GetNewByDateResponse>(
    "get",
    "/analytics/onboarding/new-by-date",
    query
  );
};
