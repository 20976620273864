// src/styles/theme.less

// @primary_red: #C84242;
// @secondary_red: #EB584E;
// @primary_yellow: #D9B64C;
// @secondary_yellow: #FADB14;
// @primary_orange: #E69700;
// @primary_blue:  #546BD6;
// @secondary_blue: #F0F3FF;
// @primary_green:  #0FB878;
// @secondary_green: #25D366;
// @primary_grey: #DEDEDE;
// @tertiary_grey: #f3f3f3;
// @secondary_grey: #F1F1F1;
// @secondary_black: #636766;

const getThemeColors = () => {
  return {
    primaryRed: "#C84242",
    secondaryRed: "#EB584E",
    primaryYellow: "#D9B64C",
    secondaryYellow: "#FADB14",
    primaryOrange: "#E69700",
    primaryBlue: "#546BD6",
    secondaryBlue: "#F0F3FF",
    primaryGreen: "#0FB878",
    secondaryGreen: "#25D366",
    primaryGrey: "#DEDEDE",
    tertiaryGrey: "#f3f3f3",
    secondaryGrey: "#F1F1F1",
    secondaryBlack: "#636766",
  };
};

export default getThemeColors;
