import './index.less';

import { Card } from 'antd';
import { AiFillExclamationCircle, AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { LocaleFormatter } from '@/locales';
import getThemeColors from '@/utils/getThemeColors';

interface Props {
    title: string;
    /*
     * The internal link to which the card should navigate to
     */
    linkTo?: string;
    /*
     * Mark card as important, should be used for cards that require immediate attention
     */
    important?: boolean;
    /*
     * Additional class names to be applied to the card
     */
    classNames?: string;
}

const DashboardCard: React.FC<Props> = ({ title, linkTo, important, children, classNames }) => {
    const colors = getThemeColors();

    const cardProps = {
        title,
        extra: linkTo && (
            <Link to={linkTo} className='view-more-link'>
                <LocaleFormatter id="dashboard.card.viewMore" />
                <AiOutlineRight />
            </Link>
        ),
        ...(important
            ? {
                  title: (
                      <div className="title-wrapper">
                          <AiFillExclamationCircle />
                          <span>{title}</span>
                      </div>
                  ),
                  bodyStyle: { border: `2px solid ${colors.secondaryRed}` },
                  headStyle: { backgroundColor: colors.secondaryRed, color: 'white' },
              }
            : {}),
    };

    return (
        <Card className={`dashboard-card-wrapper ${classNames}`} {...cardProps}>
            {children}
        </Card>
    );
};

export default DashboardCard;
