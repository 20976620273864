import type { FilterWrapperProps } from "./components/Wrapper";
import type { DateRange } from "@/interface/dateRange";
import type { TimeRangePickerProps } from "antd";
import type { Dayjs } from "dayjs";

import "./index.less";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocale } from "@/locales";
import { setDateRange } from "@/stores/filters.store";

import FilterWrapper from "./components/Wrapper";

const { RangePicker } = DatePicker;

type RangePreset =
  | "last7Days"
  | "last30Days"
  | "last90Days"
  | "lastYear"
  | "ytd"
  | "allTime";

interface Props extends FilterWrapperProps {
  /**
   * Callback to be called when a date range is selected
   */
  onDateRangeChange?: (dateRange: DateRange) => void;

  /**
   * Array of range presets to offer. Defaults to all of them.
   * @see {@link RangePreset}
   */
  presets?: RangePreset[];

  /**
   * Size of the date picker. Defaults to "medium".
   */
  size?: "small" | "medium" | "large";
  /**
   * Disable specific dates.
   * */
  disabledDate?: (current: Dayjs) => boolean;
}

/**
 * Wrapper component that provides a date range picker.
 * Data is stored in the redux store and can be accessed from it,
 * but optionally a callback can be provided to get the selected date range.
 *
 **/
const WithDateRangePicker: React.FC<Props> = ({
  children,
  onDateRangeChange,
  presets,
  size = "medium",
  disabledDate,
  ...wrapperProps
}) => {
  const { formatMessage } = useLocale();
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state) => state.filters);

  useEffect(() => {
    if (onDateRangeChange) {
      onDateRangeChange(dateRange);
    }
  }, [dateRange, onDateRangeChange]);

  const allPresets: {
    id: RangePreset;
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    {
      id: "last7Days",
      label: formatMessage({
        id: "filters.dateRangePicker.last7Days",
      }),
      value: [dayjs().subtract(7, "days"), dayjs()],
    },
    {
      id: "last30Days",
      label: formatMessage({
        id: "filters.dateRangePicker.last30Days",
      }),
      value: [dayjs().subtract(30, "days"), dayjs()],
    },
    {
      id: "last90Days",
      label: formatMessage({
        id: "filters.dateRangePicker.last90Days",
      }),
      value: [dayjs().subtract(90, "days").add(1, "hour"), dayjs()],
    },
    {
      id: "lastYear",
      label: formatMessage({
        id: "filters.dateRangePicker.lastYear",
      }),
      value: [dayjs().subtract(1, "year"), dayjs()],
    },
    {
      id: "ytd",
      label: formatMessage({
        id: "filters.dateRangePicker.ytd",
      }),
      value: [dayjs().startOf("year"), dayjs()],
    },
    {
      id: "allTime",
      label: formatMessage({
        id: "filters.dateRangePicker.allTime",
      }),
      value: [dayjs(0), dayjs()],
    },
  ];

  const rangePresets: TimeRangePickerProps["presets"] = presets
    ? allPresets.filter((preset) => presets.includes(preset.id))
    : allPresets;

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (dates) {
      const dateRange = {
        from: dayjs(dates[0]).unix() * 1000,
        till: dayjs(dates[1]).unix() * 1000,
      };

      dispatch(setDateRange(dateRange));
    }
  };

  return (
    <>
      <FilterWrapper {...wrapperProps}>
        <RangePicker
          presets={rangePresets}
          value={[
            // Don't show the start date if it's all time
            dateRange.from === 0 ? null : dayjs(dateRange.from),
            dayjs(dateRange.till),
          ]}
          onChange={onRangeChange}
          disabledDate={disabledDate}
          format="YYYY-MM-DD"
          className={`date-range-picker-wrapper ${size}`}
          allowClear={false}
        />
      </FilterWrapper>
      {children}
    </>
  );
};

export default WithDateRangePicker;
