import type { Metrics } from '../../../../../common';

// TODO: Common styles file or wrapper component for widgets
import './LatestAccessTimeAgo.less';

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { apiGetUsersLatestAccess } from '@/api/metrics/users.api';
import { useLocale } from '@/locales';

type Props = {
    /**
     * Latest access more than this value in milliseconds
     */
    moreThan: number;
    /**
     * Maximal age of metrics to use in milliseconds
     */
    metricsMaxAge?: number;
};

export function LatestAccessTimeAgo({moreThan, metricsMaxAge}: Props) {
    const { formatMessage } = useLocale();
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState<Metrics.Users.LatestAccess[]>([]);

    useEffect(() => {
        setLoading(true);
        apiGetUsersLatestAccess({
            accessAfterDate: 1, // To filter out new inboxes with no access yet
            accessBeforeDate: Date.now() - moreThan,
            ...metricsMaxAge ? { maxAge: metricsMaxAge } : {},
        })
        .then(({ data, status }) => {
            if (data) {
                setMetrics(data);
            } else {
                console.error('Failed to get latest access customers metrics. Status: ', status);
            }
        })
        .catch((error) => {
            console.error('Failed to get latest access customers metrics. Error: ', error);
        })
        .finally(() => setLoading(false));
    }, []);

    if (loading || !metrics.length) {
        // Not sure if we need to show preloader for this compopnent,
        // becase if there no zero channels customers anymore, we don't need to show this component at all,
        // but if show preloader before we know there no zero channels customers, and then not show component,
        // it will look like a bug.
        return null;
    }

    const daysAgo = (time: number) => `${Math.floor(time / 24 / 60 /60 / 1000)} days ago`;

    return (
        <div className="metrics-widget">
            <h4>
                {formatMessage(
                    { id: 'metrics.channels.latestAccessTimeAgo.title' },
                    { time: `${daysAgo(moreThan)}` }
                )}
            </h4>
            <div style={{margin: '8px'}}>
                { metrics.map(({ customerId, time }) => {
                    const diff = Date.now() - time;
                    const timeAgo = diff < 1000 * 60 * 60 * 24 * 60
                        ? daysAgo(diff)
                        : dayjs(time).fromNow();

                    return `${customerId} (${timeAgo})`;
                }).join(', ') }
            </div>
        </div>
    );
}
