import "../index.less";

import { Empty } from "antd";

import { LocaleFormatter } from "@/locales";

const NoData = () => (
  <div className="no-data-container">
    <Empty description={<LocaleFormatter id="analytics.charts.noData" />}>
      <p className="text-1">
        <LocaleFormatter id="analytics.charts.tryChangingFilters" />
      </p>
    </Empty>
  </div>
);

export default NoData;
