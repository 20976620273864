import type { Onboarding } from '../../../../../../common/analytics';

import { Line, XAxis, YAxis } from 'recharts';

import LineChart from '@/components/basic/shared/charts/LineChart';
import { useLocale } from '@/locales';
import { formatChartDate } from '@/pages/analytics/pages/newCustomers/helpers';
import getThemeColors from '@/utils/getThemeColors';

interface Props {
    currentData: Onboarding.DateCount[];
    pastData: Onboarding.DateCount[];
    groupBy: Onboarding.DateGroup;
    relativeTime: string;
}

const CompareToPastChart: React.FC<Props> = ({ currentData, pastData, groupBy, relativeTime }) => {
    const { formatMessage } = useLocale();
    const combinedData = currentData.map((current, i) => {
        // Show all current points, and respective past points if they exist
        const dataPoint = {
            currentDate: current.date,
            currentCount: current.count,
            ...(pastData[i] ? { pastDate: pastData[i].date, pastCount: pastData[i].count } : {}),
        };

        return dataPoint;
    });

    return (
        <LineChart
            data={combinedData}
            dataKey="count"
            margin={{ bottom: 24 }}
            tooltipProps={{ labelFormatter: (value) => formatChartDate(value, groupBy) }}
        >
            <XAxis dataKey="currentDate" tickFormatter={(value) => formatChartDate(value, groupBy)} />
            <XAxis
                dataKey="pastDate"
                xAxisId="past"
                label={{
                    value: relativeTime,
                    position: 'center',
                    dy: 24,
                }}
                strokeDasharray="3 4 5 2"
                fontSize={13}
                tickFormatter={(value) => (value ? formatChartDate(value, groupBy) : '')}
            />
            <YAxis />
            <Line
                type="linear"
                key="currentCount"
                dataKey="currentCount"
                strokeDasharray="0"
                animationDuration={500}
                stroke={getThemeColors().primaryGreen}
                strokeOpacity={1}
                strokeWidth={2}
            />
            <Line
                type="linear"
                key="pastCount"
                dataKey="pastCount"
                strokeDasharray="3 4 5 2"
                animationDuration={500}
                stroke={getThemeColors().primaryGreen}
                strokeOpacity={0.8}
                strokeWidth={1}
            />
        </LineChart>
    );
};

export default CompareToPastChart;
