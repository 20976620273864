import type { Partners } from '../../../../../../common';
import type { Analytics } from '../../../../../../common/routes';

import './index.less';

import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { type FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetNewByDate } from '@/api/analytics.api';
import WithDateRangePicker from '@/components/filters/WithDateRangePicker';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { searchPartnersAsync } from '@/stores/partners.action';

import { Onboarding } from '../../../../../../common/analytics';
import NewCustomersWidget from '../../../../components/analytics/NewCustomersWidget';
import Filters from './components/Filters';

const NewCustomersPage: FC = () => {
    const dispatch = useDispatch();
    const { dateRange } = useSelector((state) => state.filters);
    const { user } = useSelector((state) => state.user);

    const [data, setData] = useState<Onboarding.DateCount[]>([]);
    // We need to keep data grouped by partner separately, because relationship
    // with groupByPartner is asynchonous, so it not matches request response loaded.
    // Even if we reset state to empty array before doing request, useEffect itself is async.
    const [dataByPartner, setDataByPartner] = useState<Onboarding.DateByPartnerCount[]>([]);
    const [compareData, setCompareData] = useState<Onboarding.DateCount[]>([]);

    const [partners, setPartners] = useState<Partners.Partner[]>([]);
    const [groupBy, setGroupBy] = useState<Onboarding.DateGroup>(Onboarding.DateGroup.month);
    const [partnerId, setPartnerId] = useState<string | undefined>(undefined);
    const hasPartner = !!user?.partnerId;
    const [groupByPartner, setGroupByPartner] = useState<boolean>(hasPartner);
    const [compareToPast, setCompareToPast] = useState<boolean>(false);

    const fetchPartners = debounce(async (query: string) => {
        const partners = await dispatch(searchPartnersAsync(query));

        if (partners) {
            setPartners(partners);
        }
    }, 500);

    const onPartnerSearch = (query: string) => {
        if (query.length >= 2) {
            fetchPartners(query);
        }
    };

    useAsyncEffect(async () => {
        const query: Analytics.Onboarding.GetNewByDateQuery = {
            group: groupBy,
            before: dateRange.till,
            after: dateRange.from,
        };

        if (!hasPartner) {
            if (partnerId) {
                query.partnerId = partnerId;
            }

            if (groupByPartner) {
                query.groupByPartner = groupByPartner;
            }
        }

        const { data } = await apiGetNewByDate(query);

        if (data) {
            if (data.byPartner) {
                setDataByPartner(data.result);
            } else {
                setData(data.result);
            }
        }
    }, [dateRange, groupBy, groupByPartner, partnerId]);

    /** Compare to past */
    useAsyncEffect(async () => {
        if (compareToPast) {
            // If the date range is "allTime", we can't compare to the past
            if (dateRange.from === 0) {
                setCompareToPast(false);

                return;
            }

            // Create a new date range in the past with the same difference
            const diff = dayjs(dateRange.till).diff(dayjs(dateRange.from), 'day');
            const newDateRange = {
                till: dayjs(dateRange.from).subtract(1, 'day'),
                from: dayjs(dateRange.from).subtract(diff + 1, 'day'),
            };

            const query: Analytics.Onboarding.GetNewByDateQuery = {
                group: groupBy,
                before: newDateRange.till.unix() * 1000,
                after: newDateRange.from.unix() * 1000,
            };

            if (partnerId) {
                query.partnerId = partnerId;
            }

            const { data } = await apiGetNewByDate(query);

            if (data) {
                setCompareData(data?.result as Onboarding.DateCount[]);
            }
        } else {
            setCompareData([]);
        }
    }, [compareToPast, dateRange, groupBy, partnerId]);

    const widgetProps = {
        groupBy,
        compareData,
        ...(groupByPartner
            ? { data: dataByPartner, groupByPartner, compareToPast: false as const }
            : { data, groupByPartner, compareToPast }),
    };

    return (
        <WithDateRangePicker position="right" order={0}>
            <NewCustomersWidget {...widgetProps} />
            <Filters
                groupBy={groupBy}
                setGroupBy={setGroupBy}
                partnerId={partnerId}
                setPartnerId={(partnerId) => {
                    setGroupByPartner(false);
                    setPartnerId(partnerId);
                }}
                partners={partners}
                onPartnerSearch={onPartnerSearch}
                hasPartner={hasPartner}
                groupByPartner={groupByPartner}
                setGroupByPartner={(groupByPartner) => {
                    setPartnerId(undefined);
                    setGroupByPartner(groupByPartner);
                }}
                compareToPast={compareToPast}
                setCompareToPast={setCompareToPast}
                dateRange={dateRange}
            />
        </WithDateRangePicker>
    );
};

export default NewCustomersPage;
