export const enUS_analytics = {
  "analytics.title": "Analytics",
  "analytics.newCustomers.title": "New Customers",
  "analytics.newCustomers.filters.partner": "Partner",
  "analytics.newCustomers.filters.groupBy": "Group By",
  "analytics.newCustomers.filters.groupByPartner": "Group by partner",
  "analytics.newCustomers.filters.compareToPast": "Compare to past",
  "analytics.charts.noData": "No data",
  "analytics.charts.tryChangingFilters": "Try changing filters",
};
