import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { apiGetNewByDate } from '@/api/analytics.api';
import NewCustomersChart from '@/components/analytics/NewCustomersWidget/charts/NewCustomersChart';
import { transformByPartnerData } from '@/pages/analytics/pages/newCustomers/helpers';

import { Onboarding } from '../../../../../common/analytics';

type Props = {
    groupByPartner?: boolean;
};

const NewCustomersByYearWidget = ({groupByPartner = false}: Props) => {
    const [newCustomers, setNewCustomers] = useState<Onboarding.NewByDate | null>(null);

    useEffect(() => {
        apiGetNewByDate({
            group: Onboarding.DateGroup.month,
            before: dayjs().unix() * 1000,
            after: dayjs().add(-1, "year").unix() * 1000,
            groupByPartner,
        })
        .then(({data, status}) => {
            if (data) {
                setNewCustomers(data);
            } else {
                console.error('Error loading new customers by date. Status: ' + status);
                setNewCustomers(null);
            }
        })
        .catch((error) => {
            console.error('Error loading new customers by date.', error);
            setNewCustomers(null);
        });
    }, []);

    if (!newCustomers) {
        return null;
    }

    return (
        <NewCustomersChart
            data={newCustomers.byPartner ? transformByPartnerData(newCustomers.result) : newCustomers.result}
            groupBy={Onboarding.DateGroup.month}
            groupByPartner={newCustomers.byPartner}
        />
    );
};

export default NewCustomersByYearWidget;
