import type { Onboarding } from '../../../../../common/analytics';

import './index.less';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { LocaleFormatter } from '@/locales';

import { transformByPartnerData } from '../../../pages/analytics/pages/newCustomers/helpers';
import CompareToPastChart from './charts/CompareToPastChart';
import NewCustomersChart from './charts/NewCustomersChart';

type Props<GroupByPartner extends boolean> = {
    groupBy: Onboarding.DateGroup;
    compareData: Onboarding.DateCount[];
} & (GroupByPartner extends true
    ? {
          data: Onboarding.DateByPartnerCount[];
          groupByPartner: true;
          compareToPast: false;
      }
    : {
          data: Onboarding.DateCount[];
          groupByPartner: false;
          compareToPast: boolean;
      });

const NewCustomersWidget = <GroupByPartner extends boolean>({
    data,
    groupBy,
    groupByPartner,
    compareData,
    compareToPast,
}: Props<GroupByPartner>) => {
    const { dateRange } = useSelector((state) => state.filters);

    if (groupByPartner && compareToPast) {
        throw new Error('Grouping by partner and comparing to past is not supported together');
    }

    return (
        <div className="container analytics-container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="analytics.newCustomers.title" />
                </h1>
            </div>
            <div className="analytics-content">
                {compareToPast ? (
                    <CompareToPastChart
                        currentData={data}
                        pastData={compareData}
                        groupBy={groupBy}
                        relativeTime={dayjs(dateRange.from).from(dayjs(dateRange.till))}
                    />
                ) : (
                    <NewCustomersChart
                        data={groupByPartner ? transformByPartnerData(data) : data}
                        groupBy={groupBy}
                        groupByPartner={groupByPartner}
                    />
                )}
            </div>
        </div>
    );
};

export default NewCustomersWidget;
