import type { GeneralChartProps } from "./types";
import type { LineProps } from "recharts";

import React from "react";
import {
  CartesianGrid,
  LineChart as ReChartLineChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import NoData from "./components/NoData";

interface Props extends GeneralChartProps, LineProps {
  data: any[];
}

const LineChart: React.FC<Props> = ({
  data,
  width = 500,
  height = 400,
  margin,
  tooltipProps,
  children,
}) => {
  if (!data || data.length === 0) {
    return <NoData />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ReChartLineChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 12, right: 36, left: 0, bottom: 0, ...margin }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip  {...tooltipProps} wrapperStyle={{ zIndex: 10000 }} />
        {children}
      </ReChartLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
